@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
/* @import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel.scss';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default.scss';
*/

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

.cc-window.cc-banner {
    padding: 0.7em 0.7em;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    z-index: 9147483647;
}
/*
@media only screen and (min-width: 768px) {
    #hubspot-messages-iframe-container {
        margin-bottom: 57px;
        margin-right: 3px;
    }
}

@media only screen and (min-width: 640px) {
    #ytPlayer {
        width: 380px;
    }
}*/

.cc-btn {
    color: #FFF;
    display: block;
    padding: 0;
    font-size: .9em;
    font-weight: 700;
    border-width: 2px;
    border-style: solid;
    text-align: center;
    white-space: nowrap;
}

.cc-revoke.cc-bottom {
    bottom: 0;
    left: 0;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

.cc-theme-classic .cc-btn:last-child {
    margin-left: 1em;
}

.erp-dialog-container .mat-dialog-container {
    display: block;
    padding: 10px;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: hidden;
    outline: 0;
    width: 100%;
    height: 100%;
    min-height: inherit;
    max-height: inherit;
}
